<template>
    <div class="grid">
        <div class="col-12">
            <div style="margin-bottom:10px;">
                <a href="/adicionar-empresa"><Button label="Adicionar Empresa" class="p-button-primary btn_success" /></a>
            </div>
            <Loading v-if="loading" />
            <div class="card">
                <h5 class="titulo_pedidos">Empresas</h5>
                <table class="p-datatable-table" role="rowgroup" v-if="!loading" style="width: 100%;">
                    <thead class="p-datatable-thead" role="rowgroup" >
                        <tr role="row">
                            <th  class="p-sortable-column" tabindex="1" role="cell" style="width:15%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Código</span>
                                </div>
                            </th>
                            <th  class="p-sortable-column" tabindex="2" role="cell" style="width:25%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Empresa</span>
                                </div>
                            </th>
                            <th  class="p-sortable-column" tabindex="3" role="cell" style="width:20%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Razão Social</span>
                                </div>
                            </th>
                            <th  class="p-sortable-column" tabindex="4" role="cell" style="width:20%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">CNPJ</span>
                                </div>
                            </th>
                            <th  class="p-sortable-column" tabindex="5" role="cell" style="width:10%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Ação</span>
                                </div>
                            </th>
                            <th  class="p-sortable-column" tabindex="0" role="cell" style="width:5%;">
                                <div class="p-column-header-content">
                                    <span class="p-column-title">Principal</span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="" role="rowgroup">
                        <tr v-for="(empresa, index) in empresas" :key="index" role="row">

                            <td  role="cell">
                                {{ empresa.id }}
                            </td>
                            <td  role="cell">
                                {{empresa.nome_fantasia}}
                            </td>
                            <td  role="cell">
                                {{ empresa.razao_social }}
                            </td>
                            <td  role="cell">
                                {{ empresa.cnpj }}
                            </td>
                            <td role="cell">
                                <button class="p-button p-component p-button-icon-only p-button-text" @click="carregarEmpresa(empresa.id)">
                                    <i class="pi pi-search p-button-icon"></i>
                                </button>
                            
                                <button class="p-button p-component p-button-icon-only p-button-text" @click="carregarEmpresa(empresa.id)"><i class="pi pi-pencil"></i></button>

                                <button style="color: red;" class="p-button p-component p-button-icon-only p-button-text" @click="deletarEmpresa(empresa.id)">
                                    <i class="pi pi-times p-button-icon"></i>
                                </button>
                            </td>

                            <td  role="cell">
                                <i v-if="empresa.empresa_principal == 'SIM'" class="pi pi-check" style="color:#00CF00; font-size: bold; font-size: 20px;"></i>
                            </td>
                        </tr>
                    </tbody>
                    
                </table>
            </div>
        </div>
    </div>
</template>

<script>

import Api from '../service/api';
import Loading from '../components/Loading.vue';
export default {
    data() {
        return{
            msg_fim_sessao:'A sessão expirou. Faça Login para voltar ao Hub.',
			msg_erro:'Erro ao se comunicar com o servidor. Verifique sua internet e tente novamente. Caso o problema persista entre em contato com o suporte.',
            empresas:[]
        }
    },
    created() {
        this.empresa = this.$storage.getStorageSync("empresa")
        this.token = this.$storage.getStorageSync("jwt")
        this.empresa_id = this.empresa.id
        this.representante_id = this.$storage.getStorageSync("user").representante_id
        this.api = new Api();
        this.carregarEmpresas()
    },
    methods:{
        carregarEmpresas() {
            this.loading = true;
            this.api.empresasPorRepresentante(this.token,this.representante_id).then(data => {
                this.empresas = data.data.empresas
                this.loading = false;
                }).catch((error) => {
				if(error.response.status == 401){
					alert(this.msg_fim_sessao);
					this.$router.push('/login');	
				}else{
					alert(this.msg_erro);
				}
			}); 
            this.loading = false;
        },
        carregarEmpresa(empresa_id){
            this.$router.push({ name: 'empresa', params: { id: empresa_id } })
        },
        deletarEmpresa(empresa_id) {
            if (confirm("Deseja deletar essa empresa?")) {
                this.api.deletarEmpresa(this.token, empresa_id).then(data => {

                    if (data.data.verifica_erro == false) {
                        this.carregarEmpresas();
                    } else {
                        alert(this.msg_erro);
                    }

                    this.loading = false;
                }).catch((error) => {
                    if (error.response.status == 401) {
                        alert(this.msg_fim_sessao);
                        this.$router.push('/login');
                    } else {
                        alert(this.msg_erro);
                    }
                });
            }
        }
    },
    components: { Loading }
}
</script>

<style>
thead{
    background-color:#eff6ff;
    border-bottom: #ebeef3 solid 1px;
}
td{
    text-align: start;
    padding: 3px 10px; 
    border-bottom: #ebeef3 1px solid; 
    width:10%
}
th{
    text-align: start;
    padding: 5px 10px; 
    font-weight:normal; 
    border: #ebeef3 solid 1px
}
</style>